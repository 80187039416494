const Cards = ({ topText, bottomValue }) => {
    return ( 
        <div className="cards p-4 rounded-3xl flex flex-col bg-[#F1F1F1] h-[170px] justify-center gap-4 shadow-md">
            <div className="cardTop mx-auto">
                <h1 className="text-md text-slate-700/70">{topText}</h1>
            </div>
            <div className="cardBottom mx-auto">
                <h1 className="text-3xl">{bottomValue}</h1>
            </div>
        </div>
    );
}
 
export default Cards;
