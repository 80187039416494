import logo from '../output-onlinepngtools1.svg';
import nextArrow from '../right-arrow_15093165.svg';
import dashboard from '../layout_12226067.svg';
import addUser from '../add-user_5089847.svg';
import updateUser from '../synchronize_2749336.svg';
import logOut from '../log-out_10024690.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useState } from 'react';
import axios from 'axios';



const Navbar = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [username, setUsername] =useState('');
  useEffect(() => {
    // Retrieve both name and username from localStorage
    const storedName = localStorage.getItem('name');
    const storedUsername = localStorage.getItem('username');
    if (storedName && storedUsername) {
      setName(storedName);
      setUsername(storedUsername);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post('https://carcheckbd.com/api/logout');
      // Clear both name and username from localStorage
      localStorage.removeItem('name');
      localStorage.removeItem('username');
      navigate('/admin'); // Redirect to login page
    } catch (error) {
      console.error('Logout failed:', error.response.data);
    }
  };
const navigateAddUser = async (e) =>
{
  e.preventDefault();
  navigate('/admin/adduser');
}
  return (
    <div className="navBar w-[20%] h-full hidden lg:block fixed">
      <div className="navBarInfo h-full flex w-full bg-gradient-to-b from-[#F5F5F5] via-[#C9B9B9] to-[#EDD5D5] rounded-md flex-col">
      <div className="collapseNav">

</div>
        <div className="navBarTop w-full h-[35%] flex py-10 px-4 flex-col gap-6">
        
        <div className="logoHolder m-auto">
        <img src={logo} alt="" className='m-auto' />
        </div>
        <div className="navTopMenu flex flex-col gap-1 w-[100%] justify-end items-center px-4">
        <div className="dashboardMenu flex gap-4 justify-center items-center w-[90%] h-[50px] rounded-lg">
        <img src={dashboard} alt="" className="h-[20px]"/>
        <h1 className='text-md h-[30px] flex items-center'>Dashboard</h1>
        </div>
        <div className="addUserMenu flex gap-4 justify-center items-center w-[90%] h-[50px] rounded-lg" onClick={navigateAddUser}>
        <img src={addUser} alt="" className="h-[20px]"/>
        <h1 className='text-md h-[30px] flex items-center'>Add User</h1>
        </div>
        
        </div>
        </div>
        <hr className='w-[90%] border-slate-400 rounded-md mx-auto'/>

        <div className="navBarBottom py-10 px-4 w-[100%] flex justify-between items-center flex-col h-[65%]">
        <div className="navBottomMenu flex flex-col gap-2 w-[100%] items-center ">
            <div className="updateUserMenu flex gap-4 justify-center items-center w-[90%] h-[50px] rounded-lg">
            <img src={updateUser} alt="" className="h-[20px]"/>
            <h1 className='text-md h-[30px]  flex items-center'>Update User</h1>
            </div>
        <div className="logOutMenu flex gap-4 justify-center items-center w-[90%] h-[50px] rounded-lg" onClick={handleLogout}>
        <img src={logOut} alt="" className="h-[30px]"/>
        <h1 className='text-md h-[30px]  flex items-center'>Log out</h1>
        </div>
       
        </div>
        <div className="navUser h-[80px] w-full bg-slate-100/60 rounded-lg flex px-3 justify-around max-w-[300px]">
            <div className="dpBoxContainer h-full w-[20%] xl:w-[30%] flex">
            <div className="dpBox bg-slate-50 h-[60px] w-[60px] rounded-full my-auto flex justify-center items-center">
            <div className="dpBoxImg bg-slate-900 h-[45px] w-[45px] rounded-full">
                </div>
                </div>
            </div>
            <div className="navUserRight flex justify-center xl:justify-between items-center xl:w-[70%]">
                <div className="userInfo hidden xl:block">
                    <h1 className='text-sm md:text-md font-bold text-left'>{name}</h1>
                    <h2 className='text-xs md:text-sm text-left hover:cursor-pointer text-slate-600 hover:text-black delayTransition'>{username}</h2>
                </div>
                   <img src={nextArrow} alt="" className='h-[30px] hover:cursor-pointer hover:relative hover:h-[31px] delayTransition' />
            </div>
        </div>
        </div>

      </div>
    </div>
  );
};

export default Navbar;
