import React from "react";
 // Create a new Date object
 const today = new Date();

 // Get the day of the week (Monday, Tuesday, etc.)
 const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
 const dayName = daysOfWeek[today.getDay()]; // getDay() returns the day of the week (0-6)

 // Get the day of the month
 const dayOfMonth = today.getDate();

 // Get the month (January, February, etc.)
 const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 const monthName = monthsOfYear[today.getMonth()]; // getMonth() returns the month (0-11)

 // Get the full year
 const year = today.getFullYear();

 // Format the day suffix (e.g., 1st, 2nd, 3rd, etc.)
 const getDaySuffix = (day) => {
     if (day >= 11 && day <= 13) return "th";
     switch (day % 10) {
         case 1: return "st";
         case 2: return "nd";
         case 3: return "rd";
         default: return "th";
     }
 };

const ProfileBox = () => {
    return ( 
        
    <div className="profileBox w-[100%] top-0 right-0">
        <div className="profileBoxInfo flex px-8 py-6 items-end bg-gradient-to-r from-[#F8F8F8] via-[#F1D3D3] to-[#FFEFEF] rounded-md h-[120px]">
            <div className="profileBoxInfoRight w-1/2 flex flex-col text-left">
                <h3 className="md:text-xl text-lg">Welcome,</h3>
                <h1 className="font-medium md:text-3xl text-xl">Tanvirul Hasan</h1>
            </div>
            <div className="profileBoxInfoLeft w-1/2 flex flex-col text-right">
            <h3 className="md:text-lg text-sm font-medium">{dayName}</h3>
            <h1 className="md:text-sm text-xs text-gray-600">Today is {dayOfMonth}{getDaySuffix(dayOfMonth)} of {monthName}, {year}</h1>
            </div>
        </div>
    </div>

     );
}
 
export default ProfileBox;