import React, { useState } from 'react';
import axios from 'axios';

const AddUser = () => {
  // Define state variables for each input field
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [addressInput, setAddressInput] = useState('');
  const [dateInput, setDateInput] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation: Check if any field is empty
    if (!nameInput || !phoneInput || !addressInput || !dateInput || !imageFile) {
      alert('Please fill out all fields.');
      return;
    }

    // Prepare form data for submission
    const formData = new FormData();
    formData.append('name', nameInput);
    formData.append('phone', phoneInput);
    formData.append('address', addressInput);
    formData.append('dor', dateInput);
    formData.append('imageurl', imageFile); // This should be the actual file object

    try {
      const response = await axios.post('https://carcheckbd.com/api/users/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('User added:', response.data);
      // Optionally reset form fields after submission
      setNameInput('');
      setPhoneInput('');
      setAddressInput('');
      setDateInput('');
      setImageFile(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Display error message to user, if needed
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 bg-white shadow-md rounded">
      <div className="mb-4">
        <label className="block text-gray-700">Name:</label>
        <input
          type="text"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          required
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Phone:</label>
        <input
          type="text"
          value={phoneInput}
          onChange={(e) => setPhoneInput(e.target.value)}
          required
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Address:</label>
        <input
          type="text"
          value={addressInput}
          onChange={(e) => setAddressInput(e.target.value)}
          required
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Date of Registration:</label>
        <input
          type="date"
          value={dateInput}
          onChange={(e) => setDateInput(e.target.value)}
          required
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Image:</label>
        <input
          type="file"
          onChange={(e) => setImageFile(e.target.files[0])}
          accept="image/*"
          required
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
      </div>
      <button type="submit" className="mt-4 p-2 bg-blue-500 text-white rounded">
        Add User
      </button>
    </form>
  );
};

export default AddUser;
