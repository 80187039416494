import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import React from "react";
import logo from "../output-onlinepngtools1.svg";

// Date setup
const today = new Date();
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const dayName = daysOfWeek[today.getDay()];
const dayOfMonth = today.getDate();
const monthName = monthsOfYear[today.getMonth()];
const year = today.getFullYear();
const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) return "th";
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
};

const UserBasicView = () => {
  const { userId } = useParams(); 
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://carcheckbd.com/api/users/${userId}`);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [userId]);

  if (!userData) return <p>Loading...</p>;

  const { name, phone, address, dor, imageurl } = userData;

  return (
    <div className="userView w-full h-full flex flex-col items-center bg-gray-50">
      {/* Header section with current date */}
      <div className="profileBoxInfo w-full flex justify-between items-center bg-gradient-to-r from-gray-100 via-pink-200 to-pink-100 p-6 rounded-md shadow-md">
        <div>
          <img src={logo} alt="Logo" className="h-12" />
        </div>
        <div className="text-right">
          <h3 className="text-lg font-medium">{dayName}</h3>
          <h1 className="text-gray-600 text-sm">
            Today is {dayOfMonth}{getDaySuffix(dayOfMonth)} of {monthName}, {year}
          </h1>
        </div>
      </div>

      {/* User details section */}
      <div className="userViewBody w-full max-w-4xl p-6 mt-6 bg-white shadow-lg rounded-lg">
        <div className="flex flex-col md:flex-row gap-6">
          {/* User information */}
          <div className="md:w-1/2 flex flex-col gap-4">
            <div className="flex flex-col">
              <h2 className="text-sm text-gray-600">Registered At:</h2>
              <p className="text-lg font-bold">{new Date(dor).toLocaleDateString()}</p>
            </div>
            <div>
              <h2 className="text-2xl font-bold">{name}</h2>
            </div>
            <div className="flex flex-col">
              <h3 className="text-sm text-gray-600">Phone:</h3>
              <p className="text-lg">{phone}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="text-sm text-gray-600">Address:</h3>
              <p className="text-lg">{address}</p>
            </div>
          </div>

          {/* User profile image */}
          <div className="md:w-1/2 flex justify-center items-center">
            <img
              src={`https://carcheckbd.com:5001/${imageurl}`}
              alt={`${name}'s profile`}
              className="rounded-md max-h-60 object-cover shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBasicView;
