import './App.css';
import UserBasicView from './pages/userBasicView';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/homepage';
import Login from './pages/login';
import Navbar from './components/navbar';
import AddUser from './pages/addUser';
import UserBasicView2 from './pages/userBasicView2';
import { useState, useEffect } from 'react';

function AdminHome() {
  return (
    <>
      <Navbar />
      <HomePage />
    </>
  );
}

function AdminAddUser() {
  return (
    <>
      <Navbar />
      <AddUser />
    </>
  );
}

// Protected Route Component
const ProtectedRoute = ({ element }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  
  // If not logged in, redirect to login page
  return isLoggedIn ? element : <Navigate to="/admin" />;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Set login state based on localStorage value
    const loginStatus = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(loginStatus === 'true');
  }, []);

  return (
    <div className="App w-screen h-screen">
      <BrowserRouter>
        <Routes>
          <Route path='/admin' element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          
          {/* Protected routes that require login */}
          <Route path="/admin/adduser" element={<ProtectedRoute element={<AdminAddUser />} />} />
          <Route path="/admin/home" element={<ProtectedRoute element={<AdminHome />} />} />
          
          {/* Public routes */}
          <Route path="/user/:userId" element={<UserBasicView />} />
          <Route path="/users/ajjshhioeh" element={<UserBasicView2 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
