import ProfileBox from "../components/profileBox";
import Cards from "../components/card";
import Tables from "../components/tables";

const HomePage = () => {
    return ( 
    <div className="homepage w-[100%] h-full flex items-center right-0 lg:w-[80%] flex-col absolute">
            <ProfileBox/>
            <div className="homePageBody w-[100%] lg:w-[80%] p-10">
                <div className="homePageVisuals w-full flex  justify-center lg:justify-between gap-8 flex-wrap lg:flex-nowrap m-4 mb-10">
                    <div className="box1 md:w-[250px] w-[310px]">
                        <Cards topText="Today's Visits" bottomValue="1000"/>
                    </div>
                    <div className="box2  md:w-[250px] w-[310px]">
                        <Cards topText="Most Visits" bottomValue="Tamim Iqbal"/>
                    </div>
                    <div className="box3  md:w-[250px] w-[310px]">
                        <Cards topText="Total Visits" bottomValue="100,000"/>
                    </div>
                </div> 
                <Tables/>
            </div>
    </div> );
}
 
export default HomePage;
