import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("userName", username);
      console.log("password", password);
      // Make a POST request to the dummy backend API
      const response = await axios.post('https://carcheckbd.com/api/login', 
        { username, password }, 
        { withCredentials: true }
      );
console.log("Tanvir", response);
      // Check the response and simulate login success
      if (response.data && response.data.user) {
        console.log('Login successful:', response.data);

        // Store the dummy login status
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('name', response.data.user.name);
        localStorage.setItem('username', response.data.user.username);

        // Set the login state
        setIsLoggedIn(true);

        // Navigate to the admin homepage
        navigate('/admin/home');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      setErrorMessage('Failed to connect to backend. Please try again.');
      console.error('Login failed:', error?.response?.data || error.message);
    }
  };

  return (
    <div className="login w-full h-full bg-[#f6ecec] flex justify-center items-center">
      <div className="loginBody w-[90%] h-[90%] bg-white rounded-lg flex justify-center items-center">
        <form onSubmit={handleSubmit} className="loginForm bg-[#D8D8D8] py-10 px-10 flex flex-col justify-center gap-3 rounded-2xl min-w-[300px] m-auto">
          <div className="formHeadline my-4">
            <h1 className="text-3xl font-extrabold mb-4">Welcome Back</h1>
            <h4>Please enter your details to sign in</h4>
          </div>
          <div className="formInput flex text-left justify-center flex-col items-center gap-3 mb-2">
            <div className="formTop flex flex-col justify-center gap-2">
              <label htmlFor="username">Username</label>
              <input type="text" placeholder="Enter your username" value={username} onChange={e => setUsername(e.target.value)} className="rounded-lg h-12 p-2 w-[250px]" />
            </div>
            <div className="formBottom flex flex-col justify-center gap-2">
              <label htmlFor="password">Password</label>
              <input type="password" placeholder="Enter your password" value={password} onChange={e => setPassword(e.target.value)} className="rounded-lg h-12 p-2 w-[250px]" />
            </div>
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} 
          <button type="submit" className="p-2 rounded-2xl hover:cursor-pointer text-white hover:bg-white hover:text-[#1B244D] transition-all delay-1 w-[150px] bg-black mx-auto font-bold h-[48px]">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
