import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import React from "react";
import logo from "../output-onlinepngtools1.svg";
import profilePic from "../SabbirHossain.png";

// Date setup
const today = new Date();
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const dayName = daysOfWeek[today.getDay()];
const dayOfMonth = today.getDate();
const monthName = monthsOfYear[today.getMonth()];
const year = today.getFullYear();
const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) return "th";
  switch (day % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
};

const UserBasicView2 = () => {
  const { userId } = useParams(); 
  const [userData, setUserData] = useState(null);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
  //       const response = await axios.get(`https://carcheckbd.com/api/users/${userId}`);
  //       setUserData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching user:", error);
  //     }
  //   };

  //   fetchUser();
  // }, [userId]);

  // if (!userData) return <p>Loading...</p>;

  // const { name, phone, address, dor, imageurl } = userData;

  return (
    <div className="userView w-full h-full flex flex-col items-center bg-white">
      {/* Header section with current date */}
      <div className="profileBoxInfo w-full flex justify-between items-center bg-gradient-to-r from-[#F8F8F8] via-[#F1D3D3] to-[#FFEFEF] p-6 rounded-md shadow-md">
        <div>
          <img src={logo} alt="Logo" className="h-12" />
        </div>
        <div className="text-right">
          <h3 className="text-lg font-medium">{dayName}</h3>
          <h1 className="text-gray-600 md:text-sm text-xs">
            Today is {dayOfMonth}{getDaySuffix(dayOfMonth)} of {monthName}, {year}
          </h1>
        </div>
      </div>

      {/* User details section */}
      <div className="userViewBody userViewBody flex flex-col w-full p-5 h-[80%] justify-start">
      <div className="userViewBodyTop flex gap-4 w-full p-1 md:p-4 border rounded-xl bg-slate-100 flex-col h-[80%]">
          <div className="topUp flex gap-4 w-full p-2 md:p-4 my-auto">
            <div className="topLeft flex flex-col w-2/3 justify-center gap-4">
              <div className="leftUp flex justify-start flex-col">
              <h1 className="text-2xl text-left md:text-center font-bold">
                  Sabbir Hasan
                </h1>
              </div>
              <div className="leftDown flex md:mx-auto flex-col">
              <h1 className="text-slate-500 text-left text-sm">Registered No.</h1>
              <h1 className="font-bold text-left mb-2">EA67T09</h1>
              <h1 className="text-slate-500 text-left text-sm">Phone Number</h1>
              <h1 className="font-bold text-left">01700000000</h1>
              </div>
            </div>
            <div className="topRight w-1/2 mx-auto sm:my-0 my-auto flex justify-center">
              <img src={profilePic} alt={'Sabbir Hasan Profile'} className="rounded-md max-h-60 object-cover shadow-md" />
            </div>
          </div>
          <div className="topBottom w-full">
            <div className="profileInfo w-full flex flex-col p-4 gap-4">
              <div className="phoneNo text-left md:text-center">
              <h1 className="text-slate-500 text-sm">Emergency Contact</h1>
              <h1 className="font-bold">01900000000</h1>
              </div>
              <div className="addressInfo text-left md:text-center">
                <h1 className="text-slate-500 text-sm">Address</h1>
                <h1 className="font-bold">Gulshan Avenue, Dhaka</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBasicView2;
